<template>
  <div>
    <!-- 新增竞价资源(履约订金/仓单) -->
    <el-dialog
      v-dialogDrag
      title="新增竞价资源"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div>
        <el-form ref="select" v-if="!isFromWarehouse">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="资源挂单方式" prop>
                <!-- :disabled="!right.addSpot" -->
                <el-radio
                  v-model="biddingType"
                  @change="selectChange"
                  label="holdSell"
                  >仓单竞价</el-radio
                >
                <!--  :disabled="!right.addPreSell"   -->
                <el-radio
                  v-model="biddingType"
                  @change="selectChange"
                  label="marginSell"
                  >履约订金竞价</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form
          ref="marginForm"
          :rules="marginRules"
          :model="marginForm"
          label-position="left"
          v-show="biddingType === 'marginSell'"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="公示开始时间"
                prop="showTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="marginForm.showTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="竞价开始时间"
                prop="startTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="marginForm.startTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="竞价结束时间"
                prop="endTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="marginForm.endTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="持仓ID" prop="holdId" label-width="120px">
                <el-select
                  class="width-100"
                  v-model="marginForm.holdId"
                  @change="selectChange"
                >
                  <el-option
                    v-for="items in categoryOptions"
                    :key="items.holdId"
                    :label="items.holdId + '-' + items.className"
                    :value="items.holdId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-for="(item, index) in colList" :key="index">
              <el-form-item :label="item.name" label-width="120px">
                <el-input disabled :value="item.value"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="生产厂家" prop="manufacturer" label-width="120px">
                <el-input v-model="marginForm.manufacturer"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item
                label="挂单数量"
                prop="quantity"
                label-width="120px"
              >
                <el-input
                  type="number"
                  v-model="marginForm.quantity"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="挂单重量" prop="weight" label-width="120px">
                <!-- :disabled="true" -->
                <el-input v-model="marginForm.weight">
                  <template slot="append">{{ marginForm.unit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="基准单价" prop="price" label-width="120px">
                <el-input v-model="marginForm.price">
                  <template slot="append">元/{{ marginForm.unit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="起拍总价"
                prop="startPrice"
                label-width="120px"
              >
                <el-input v-model="marginForm.startPrice">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="竞拍方式"
                prop="auctionStyle"
                label-width="120px"
              >
                <el-radio-group v-model="marginForm.auctionStyle" size="small">
                  <el-radio label="1">普通</el-radio>
                  <el-radio label="2">跌价式</el-radio>
                  <el-radio label="3">大宗竞拍</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              class="stepPriceCon"
              v-if="saleList.length != 0 && marginForm.auctionStyle == 2"
            >
              <span
                v-for="(item, index) of saleList"
                :key="index"
                @click="modifyStepPrice(item, index)"
                >{{ item.saleTime }} &nbsp;
                {{ item.salePrice }}元&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
            </el-col>
            <el-col
              :span="24"
              class="stepPriceCon"
              v-if="priceForm.protectPrice && marginForm.auctionStyle == 2"
            >
              <p @click="priceDialogVisible = true">
                <span>保护价：{{ priceForm.protectPrice }}元</span>&nbsp;&nbsp;
                <span
                  >跌价幅度 ：每{{ priceForm.saleSecond }}秒减{{
                    priceForm.decreasePrice
                  }}元</span
                >&nbsp;&nbsp;
              </p>
            </el-col>
            <el-col :span="24">
              <el-col :span="6" v-if="marginForm.auctionStyle == 2">
                <div class="stepPriceBtn" @click="centerDialogVisible = true">
                  添加阶梯价格
                </div>
              </el-col>
              <el-col :span="6" v-if="marginForm.auctionStyle == 2">
                <div class="stepPriceBtn" @click="priceDialogVisible = true">
                  添加跌价幅度
                </div>
              </el-col>
            </el-col>

            <el-col :span="12" v-if="marginForm.auctionStyle == 1">
              <!-- <el-form-item label="加价幅度" prop="addPrice">
              <el-input v-model="marginForm.addPrice"></el-input>
              </el-form-item>-->
              <el-form-item
                label="加价幅度"
                prop="addPrice"
                label-width="120px"
              >
                <!-- <el-select class="width-100" v-model="marginForm.addPrice">
                  <el-option v-for="item in auctionRule" :key="item" :label="item" :value="item"></el-option>
                </el-select> -->
                <el-input v-model="marginForm.addPrice">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                label="预计入库时间"
                prop="putWarehousTime"
                label-width="120px"
              >
                <el-date-picker
                  placeholder="请考虑入库后质检所需要的时间"
                  class="width-100"
                  v-model="marginForm.putWarehousTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="开票期限"
                prop="openTicketTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="marginForm.openTicketTime"
                  type="date"
                  placeholder="请选择日期"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="业务负责人"
                prop="businessName"
                label-width="120px"
              >
                <el-input v-model="marginForm.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系电话"
                prop="businessPhone"
                label-width="120px"
              >
                <el-input v-model="marginForm.businessPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isAuction && marginForm.auctionStyle == 1">
              <el-form-item
                label="交易方式"
                prop="tradeWay"
                label-width="120px"
              >
                <el-radio-group v-model="marginForm.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="marginForm.tradeWay == 'P'">
              <el-form-item
                label="最小摘单重量"
                prop="minPickingWeight"
                label-width="120px"
              >
                <el-input v-model="marginForm.minPickingWeight"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
            <el-form-item label="备注" prop="memo" label-width="120px">
              <el-input v-model="marginForm.memo"></el-input>
            </el-form-item>
            </el-col>-->
            <!-- <el-col :span="24">
              <el-form-item label="商品图片" prop="image" label-width="120px">
                <el-upload
                  class="avatar-uploader"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="postData"
                >
                  <img v-if="marginForm.image" :src="marginForm.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>-->
          </el-row>
        </el-form>
        <el-form
          ref="holdForm"
          :rules="holdRules"
          :model="holdForm"
          label-position="left"
          v-show="biddingType === 'holdSell'"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="公示开始时间"
                prop="showTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="holdForm.showTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="竞价开始时间"
                prop="startTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="holdForm.startTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="竞价结束时间"
                prop="endTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="holdForm.endTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="持仓ID" prop="holdId" label-width="120px">
                <el-select
                  class="width-100"
                  v-model="holdForm.holdId"
                  @change="selectChange"
                >
                  <el-option
                    v-for="items in categoryOptions"
                    :key="items.holdId"
                    :label="items.holdId + '-' + items.className"
                    :value="items.holdId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-for="(item, index) in colList" :key="index">
              <el-form-item :label="item.name" label-width="120px">
                <el-input disabled :value="item.value"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="生产厂家" prop="manufacturer" label-width="120px">
                <el-input v-model="holdForm.manufacturer"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item
                label="挂单数量"
                prop="quantity"
                label-width="120px"
              >
                <el-input type="number" v-model="holdForm.quantity"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="挂单重量" prop="weight" label-width="120px">
                <el-input v-model="holdForm.weight">
                  <template slot="append">{{ holdForm.unit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="基准单价" prop="price" label-width="120px">
                <el-input v-model="holdForm.price">
                  <template slot="append">元/{{ holdForm.unit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="起拍总价"
                prop="startPrice"
                label-width="120px"
              >
                <el-input v-model="holdForm.startPrice">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="竞拍方式"
                prop="auctionStyle"
                label-width="120px"
              >
                <el-radio-group v-model="holdForm.auctionStyle" size="small">
                  <el-radio label="1">普通</el-radio>
                  <el-radio label="2">跌价式</el-radio>
                  <el-radio label="3">大宗竞拍</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              class="stepPriceCon"
              v-if="saleList.length != 0 && holdForm.auctionStyle == 2"
            >
              <span
                v-for="(item, index) of saleList"
                :key="index"
                @click="modifyStepPrice(item, index)"
                >{{ item.saleTime }} &nbsp;
                {{ item.salePrice }}元&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
            </el-col>
            <el-col
              :span="24"
              class="stepPriceCon"
              v-if="priceForm.protectPrice && holdForm.auctionStyle == 2"
            >
              <p @click="priceDialogVisible = true">
                <span>保护价：{{ priceForm.protectPrice }}元</span>&nbsp;&nbsp;
                <span
                  >跌价幅度 ：每{{ priceForm.saleSecond }}秒减{{
                    priceForm.decreasePrice
                  }}元</span
                >&nbsp;&nbsp;
              </p>
            </el-col>
            <el-col :span="24">
              <el-col :span="6" v-if="holdForm.auctionStyle == 2">
                <div class="stepPriceBtn" @click="centerDialogVisible = true">
                  添加阶梯价格
                </div>
              </el-col>
              <el-col :span="6" v-if="holdForm.auctionStyle == 2">
                <div class="stepPriceBtn" @click="priceDialogVisible = true">
                  添加跌价幅度
                </div>
              </el-col>
            </el-col>

            <el-col :span="12" v-if="holdForm.auctionStyle == 1">
              <!-- <el-form-item label="加价幅度" prop="addPrice">
              <el-input v-model="marginForm.addPrice"></el-input>
              </el-form-item>-->
              <el-form-item
                label="加价幅度"
                prop="addPrice"
                label-width="120px"
              >
                <!-- <el-select class="width-100" v-model="holdForm.addPrice">
                  <el-option v-for="item in auctionRule" :key="item" :label="item" :value="item"></el-option>
                </el-select> -->
                <el-input v-model="holdForm.addPrice">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="开票期限"
                prop="openTicketTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="holdForm.openTicketTime"
                  type="date"
                  placeholder="请选择日期"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="业务负责人"
                prop="businessName"
                label-width="120px"
              >
                <el-input v-model="holdForm.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系电话"
                prop="businessPhone"
                label-width="120px"
              >
                <el-input v-model="holdForm.businessPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isAuction && holdForm.auctionStyle == 1">
              <el-form-item
                label="交易方式"
                prop="tradeWay"
                label-width="120px"
              >
                <el-radio-group v-model="holdForm.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="holdForm.tradeWay == 'P'">
              <el-form-item
                label="最小摘单重量"
                prop="minPickingWeight"
                label-width="120px"
              >
                <el-input v-model="holdForm.minPickingWeight"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
            <el-form-item label="备注" prop="memo" label-width="120px">
              <el-input v-model="holdForm.memo"></el-input>
            </el-form-item>
            </el-col>-->
            <!-- <el-col :span="12">
              <el-form-item label="商品图片" prop="image" label-width="120px">
                <el-upload
                  class="avatar-uploader"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="postData"
                >
                  <img v-if="holdForm.image" :src="holdForm.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>-->
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm()" :disabled="isAddLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="添加阶梯价"
      :close-on-click-modal="false"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <el-form ref="stepForm" :rules="stepFormRules" :model="stepForm">
        <el-form-item label="跌价开始时间" prop="saleTime" label-width="120px">
          <el-date-picker
            class="width-100"
            v-model="stepForm.saleTime"
            type="datetime"
            placeholder="请选择跌价时间"
            :picker-options="putWarehousTimePicker"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="起拍总价" prop="salePrice" label-width="120px">
          <el-input v-model="stepForm.salePrice">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPrices()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="添加跌价幅度"
      :close-on-click-modal="false"
      :visible.sync="priceDialogVisible"
      width="500px"
      center
    >
      <el-form ref="priceForm" :rules="priceFormRules" :model="priceForm">
        <el-form-item label="保护价格" prop="protectPrice" label-width="120px">
          <el-input v-model="priceForm.protectPrice">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="跌价间隔时间"
          prop="saleSecond"
          label-width="120px"
        >
          <el-input v-model="priceForm.saleSecond">
            <template slot="append">秒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="递减价格" prop="decreasePrice" label-width="120px">
          <el-input v-model="priceForm.decreasePrice">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRangePrices()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      id="d1"
      v-dialogDrag
      title="温馨提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible1"
      width="30%"
      :before-close="handledialogVisible1"
    >
      <el-form
        ref="newForm"
        :rules="newFormRules"
        :model="newForm"
        v-show="biddingType === 'marginSell'"
      >
        <el-row :gutter="20">
          <el-col :span="24" style="font-weight: 700">
            本委托须冻结履约履约订金: ￥{{ deposit }}
            <br />请联系财务人员或企业管理员进行支付操作！
            <!-- <el-form-item label="履约订金" prop="deposit">
              <el-input v-model="deposit" type="text" disabled></el-input>
            </el-form-item>-->
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="confirmSure()" :loading="isAddLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    right: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("请输入正整数"));
        } else if (!this.marginForm.holdId && !this.holdForm.holdId) {
          return callback(new Error("请先选择持仓ID"));
        } else if (value > Number(this.maxQuantity)) {
          return callback(new Error("超过可挂单数量"));
        } else {
          callback();
        }
      } else {
        callback();
      }
      // if (value == "") {
      //   callback(new Error("请输入数量"));
      // } else if (!reg.test(value)) {
      //   return callback(new Error("请输入正整数"));
      // } else if (!this.marginForm.holdId && !this.holdForm.holdId) {
      //   return callback(new Error("请先选择持仓ID"));
      // } else if (value > Number(this.maxQuantity)) {
      //   return callback(new Error("超过可挂单数量"));
      // } else {
      //   callback();
      // }
    };
    var checkmarginFormaddPrice = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (value == "" && this.marginForm.auctionStyle == 1) {
        callback(new Error("选择加价幅度"));
      } else {
        callback();
      }
    };
    var checkHoldaddPrice = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (value == "" && this.holdForm.auctionStyle == 1) {
        callback(new Error("选择加价幅度"));
      } else {
        callback();
      }
    };
    var checkShowTime = (rule, value, callback) => {
      let currentTime = new Date().getTime();
      let newValue = value.getTime();
      console.log(value);
      if (value == "") {
        callback(new Error("请选择公示开始时间"));
      } else if (newValue <= currentTime) {
        return callback(new Error("公示时间应晚于当前时间"));
      } else {
        callback();
      }
    };
    var checkStartTime = (rule, value, callback) => {
      const that = this;
      console.log(that.marginForm.showTime);
      if (!that.marginForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (value == "") {
        callback(new Error("请选择开始时间"));
      } else if (that.marginForm.showTime) {
        let currentTime = that.marginForm.showTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("开始时间应晚于公示时间"));
        } else {
          callback();
        }
      }
    };
    var checkEndTime = (rule, value, callback) => {
      const that = this;
      if (!that.marginForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.marginForm.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (value == "") {
        callback(new Error("请选择结束时间"));
      } else if (that.marginForm.startTime) {
        let currentTime = that.marginForm.startTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("结束时间应晚于开始时间"));
        } else {
          callback();
        }
      }
    };
    var checkMShowTime = (rule, value, callback) => {
      let currentTime = new Date().getTime();
      let newValue = value.getTime();
      if (value == "") {
        callback(new Error("请选择公示开始时间"));
      } else if (newValue <= currentTime) {
        return callback(new Error("公示时间应晚于当前时间"));
      } else {
        callback();
      }
    };
    var checkMStartTime = (rule, value, callback) => {
      const that = this;
      if (!that.holdForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (value == "") {
        callback(new Error("请选择开始时间"));
      } else if (that.holdForm.showTime) {
        let currentTime = that.holdForm.showTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("开始时间应晚于公示时间"));
        } else {
          callback();
        }
      }
    };
    var checkMEndTime = (rule, value, callback) => {
      const that = this;
      if (!that.holdForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.holdForm.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (value == "") {
        callback(new Error("请选择结束时间"));
      } else if (that.holdForm.startTime) {
        let currentTime = that.holdForm.startTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("结束时间应晚于开始时间"));
        } else {
          callback();
        }
      }
    };
    var checkPutWarehousDay = (rule, value, callback) => {
      const that = this;
      if (!that.marginForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.marginForm.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (!that.marginForm.endTime) {
        callback(new Error("请选择结束时间"));
      } else if (value == "") {
        callback(new Error("请选择预计入库时间"));
      } else if (that.marginForm.endTime) {
        let currentTime = that.marginForm.endTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("预计入库时间应晚于竞价结束时间"));
        } else {
          callback();
        }
      }
    };
    var checkOpenTicketDay = (rule, value, callback) => {
      const that = this;
      if (!that.holdForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.holdForm.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (!that.holdForm.endTime) {
        callback(new Error("请选择结束时间"));
      } else if (value == "") {
        callback(new Error("请选择开票期限"));
      } else if (that.holdForm.endTime) {
        let currentTime = that.holdForm.endTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("开票时间应晚于竞价结束时间"));
        } else {
          callback();
        }
      }
    };
    var checkTicketDay = (rule, value, callback) => {
      const that = this;
      if (!that.marginForm.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.marginForm.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (!that.marginForm.endTime) {
        callback(new Error("请选择结束时间"));
      } else if (!that.marginForm.putWarehousTime) {
        callback(new Error("请选择预计入库时间"));
      } else if (value == "") {
        callback(new Error("请选择开票期限"));
      } else if (that.marginForm.endTime && that.marginForm.putWarehousTime) {
        let currentTime = that.marginForm.endTime.getTime();
        let currentputTime = that.marginForm.putWarehousTime.getTime();
        let newValue = value.getTime();
        if (
          Number(newValue) <= Number(currentTime) ||
          Number(newValue) <= Number(currentputTime)
        ) {
          return callback(new Error("开票时间应晚于竞价结束时间和入库时间"));
        } else {
          callback();
        }
      }
    };
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入重量"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else if (!this.marginForm.holdId && !this.holdForm.holdId) {
        return callback(new Error("请先选择持仓ID"));
      } else if (value > Number(this.maxWeight)) {
        return callback(new Error("超过可挂单重量"));
      } else {
        callback();
      }
    };
    var checkPrice = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入价格"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("起拍总价必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    // 正整数
    var positiveNum = (rule, value, callback) => {
      let reg = /^[+]{0,1}(\d+)$/;
      if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("必须是正数且不能超过一位小数"));
      } else {
        callback();
      }
    };
    var checkTRime = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,1})?$/;
      if (value == "") {
        return callback(new Error("请输入跌价时间"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("跌价时间必须是正数且不能超过一位小数"));
      } else {
        callback();
      }
    };
    var checkSaleTime = (rule, value, callback) => {
      const that = this;
      let len = that.saleTimeList.length;
      if (this.biddingType == "holdSell") {
        if (!that.holdForm.startTime) {
          return callback(new Error("请先选择开始时间"));
        } else if (!that.holdForm.endTime) {
          callback(new Error("请选择结束时间"));
        } else if (value == "") {
          callback(new Error("请选择跌价开始时间"));
        } else if (that.holdForm.startTime && that.holdForm.endTime) {
          let currentTime = that.holdForm.endTime.getTime();
          let currentstartTime = that.holdForm.startTime.getTime();
          let newValue = value.getTime();
          if (newValue <= currentstartTime) {
            return callback(new Error("跌价开始时间应晚于竞价开始时间"));
          } else if (newValue >= currentTime) {
            return callback(new Error("跌价开始时间应早于竞价结束时间"));
          }
          let m = 0;
          if (len != 0) {
            for (let i = 0; i < len; i++) {
              if (newValue == that.saleTimeList[i].getTime()) {
                return callback(new Error("跌价开始时间不能相等"));
              } else {
                m++;
              }
            }
            if (m == len) {
              callback();
            }
          } else {
            callback();
          }
        }
      } else if (this.biddingType == "marginSell") {
        if (!that.marginForm.startTime) {
          return callback(new Error("请先选择开始时间"));
        } else if (!that.marginForm.endTime) {
          callback(new Error("请选择结束时间"));
        } else if (value == "") {
          callback(new Error("请选择跌价开始时间"));
        } else if (that.marginForm.startTime && that.marginForm.endTime) {
          let currentTime = that.marginForm.endTime.getTime();
          let currentstartTime = that.marginForm.startTime.getTime();
          let newValue = value.getTime();
          if (newValue <= currentstartTime) {
            return callback(new Error("跌价开始时间应晚于竞价开始时间"));
          } else if (newValue >= currentTime) {
            return callback(new Error("跌价开始时间应早于竞价结束时间"));
          }
          let m = 0;
          if (len != 0) {
            for (let i = 0; i < len; i++) {
              if (newValue == that.saleTimeList[i].getTime()) {
                return callback(new Error("跌价开始时间不能相等"));
              } else {
                m++;
              }
            }
            if (m == len) {
              callback();
            }
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    var checkMinPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (
        this.marginForm.auctionStyle == 1 &&
        this.marginForm.tradeWay == "P" &&
        !value
      ) {
        return callback(new Error("请输入最小摘单重量"));
      } else if (value) {
        if (!this.marginForm.weight) {
          return callback(new Error("请输入挂单重量"));
        } else if (!reg.test(value) || Number(value) <= 0) {
          return callback(
            new Error("最小摘单重量必须是正数且不能超过两位小数")
          );
        } else if (Number(value) >= Number(this.marginForm.weight)) {
          return callback(new Error("最小摘单重量必须是小于挂单重量"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkHoldMinPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (
        this.holdForm.auctionStyle == 1 &&
        this.holdForm.tradeWay == "P" &&
        !value
      ) {
        return callback(new Error("请输入最小摘单重量"));
      } else if (value) {
        if (!this.holdForm.weight) {
          return callback(new Error("请输入挂单重量"));
        } else if (!reg.test(value) || Number(value) <= 0) {
          return callback(
            new Error("最小摘单重量必须是正数且不能超过两位小数")
          );
        } else if (Number(value) >= Number(this.holdForm.weight)) {
          return callback(new Error("最小摘单重量必须是小于挂单重量"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      isAuction: window.g.IS_AUCTION_FX,
      colList: [],
      biddingType: "holdSell", //选择竞价方式
      dialogVisible: false,
      dialogVisible1: false,
      priceDialogVisible: false,
      gradeName: null,
      currentCommodityId: null, // 用于加价幅度获取
      centerDialogVisible: false,
      saleList: [],
      saleTimeList: [],
      stepForm: {
        saleTime: null,
        salePrice: null
      },
      priceForm: {
        decreasePrice: null, // 递减价格
        protectPrice: null, // 保护价格
        saleSecond: null //递减金额
      },
      marginForm: {
        auctionStyle: null, //竞拍方式 1-普通，2-跌价式，3-大宗竞拍
        holdId: null, //持仓id
        addPrice: null, // 加价幅度
        contactName: "", // 联系人
        contactPhone: "", // 联系电话
        endTime: null, // 竞拍结束时间
        image: "", // 商品图片
        memo: "", // 备注
        openTicketTime: null, // 开票期限
        manufacturer: "", // 生产厂家
        price: null, // 单价
        quantity: null, // 数量
        showTime: null, // 公示开始时间
        startPrice: null, // 起拍总价
        startTime: null, // 竞拍开始时间
        weight: null, // 重量
        unit: null, // 参持仓单位
        businessName: null,
        businessPhone: null,
        putWarehousTime: null, // 预计入库时间
        tradeWay: null, // A：整批P：分销
        minPickingWeight: null //分销的话最小摘单重量
        // code: null,
        // marginPassWord: null
      },
      newForm: {
        code: null,
        marginPassWord: null
      },
      maxWeight: null,
      maxQuantity: null,
      holdForm: {
        auctionStyle: null, //竞拍方式 1-普通，2-跌价式，3-大宗竞拍
        holdId: null, //持仓id
        addPrice: null, // 加价幅度
        contactName: "", // 联系人
        contactPhone: "", // 联系电话
        endTime: null, // 竞拍结束时间
        image: "", // 商品图片
        memo: "", // 备注
        openTicketTime: null, // 开票期限
        manufacturer: "", // 生产厂家
        price: null, // 单价
        quantity: null, // 数量
        showTime: null, // 公示开始时间
        startPrice: null, // 起拍总价
        startTime: null, // 竞拍开始时间
        weight: null, // 重量
        unit: null, // 参持仓单位
        businessName: null,
        businessPhone: null,
        tradeWay: null, // A：整批P：分销
        minPickingWeight: null //分销的话最小摘单重量
      },
      marginRules: {
        showTime: [
          { required: true, message: "请选择公示开始时间", trigger: "change" },
          { validator: checkShowTime, trigger: "blur" }
        ], // 公示开始时间
        startTime: [
          { required: true, message: "请选择竞拍开始时间", trigger: "change" },
          { validator: checkStartTime, trigger: "blur" }
        ], // 竞拍开始时间
        endTime: [
          { required: true, message: "请选择竞拍结束时间", trigger: "change" },
          { validator: checkEndTime, trigger: "blur" }
        ], // 竞拍结束时间
        // manufacturer: [
        //   { required: true, message: "请选择生产厂家", trigger: "change" }
        // ],// 生产厂家
        holdId: [
          { required: true, message: "请选择持仓ID", trigger: "change" }
        ], // 商品品种id
        quantity: [
          { required: false, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: "blur" }
        ], // 数量
        weight: [
          {
            required: true,
            message: "请输入挂单重量",
            trigger: "change"
          },
          { validator: checkPutHoldWeight, trigger: "change" }
        ], // 重量
        auctionStyle: [
          {
            required: true,
            message: "请选择竞拍方式",
            trigger: "change"
          }
        ],
        price: [
          { required: true, message: "请输入基准单价", trigger: "change" },
          { validator: positiveNum, trigger: "change" }
        ], // 单价
        startPrice: [
          { required: true, message: "请输入起拍总价", trigger: "change" },
          { validator: checkPrice, trigger: "change" }
        ], // 起拍总价
        addPrice: [
          { required: true, message: "请输入加价幅度", trigger: "change" },
          { validator: positiveNum, trigger: "change" }
        ], // 加价幅度
        openTicketTime: [
          { required: true, message: "请选择开票期限", trigger: "change" },
          { validator: checkTicketDay, trigger: "change" }
        ], // 开票期限
        putWarehousTime: [
          { required: true, message: "请选择预计入库时间", trigger: "change" },
          { validator: checkPutWarehousDay, trigger: "change" }
        ], // 预计入库时间
        memo: [{ required: false, message: "请选择备注", trigger: "change" }], // 备注
        image: [
          { required: false, message: "请选择商品图片", trigger: "change" }
        ], // 商品图片
        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" }
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" }
        ],
        minPickingWeight: [
          { required: false, message: "请输入最小摘单重量", trigger: "change" },
          { validator: checkMinPickWeight, trigger: "change" }
        ]
      },
      newFormRules: {
        //  code: [{ required: true, message: "请输入短信验证码", trigger: "change" }],
        // marginPassWord: [{ required: true, message: "请输入资金密码", trigger: "change" }],
      },
      stepFormRules: {
        saleTime: [
          { required: true, message: "请选择跌价时间", trigger: "change" },
          { validator: checkSaleTime, trigger: "change" }
        ],
        salePrice: [
          { required: true, message: "请输入跌价价格", trigger: "change" }
        ]
      },
      priceFormRules: {
        protectPrice: [
          { required: true, message: "请选择保护价格", trigger: "change" },
          { validator: checkPrice, trigger: "change" }
        ],
        decreasePrice: [
          { required: true, message: "请输入跌价价格", trigger: "change" },
          { validator: checkPrice, trigger: "change" }
        ],
        saleSecond: [
          { required: true, message: "请输入跌价间隔时间", trigger: "change" },
          { validator: checkTRime, trigger: "change" }
        ]
      },
      holdRules: {
        showTime: [
          { required: true, message: "请选择公示开始时间", trigger: "change" },
          { validator: checkMShowTime, trigger: "blur" }
        ], // 公示开始时间
        startTime: [
          { required: true, message: "请选择竞拍开始时间", trigger: "change" },
          { validator: checkMStartTime, trigger: "blur" }
        ], // 竞拍开始时间
        endTime: [
          { required: true, message: "请选择竞拍结束时间", trigger: "change" },
          { validator: checkMEndTime, trigger: "blur" }
        ], // 竞拍结束时间
        holdId: [
          { required: true, message: "请选择持仓ID", trigger: "change" }
        ], // 商品品种id
        auctionStyle: [
          {
            required: true,
            message: "请选择竞拍方式",
            trigger: "change"
          }
        ],
        quantity: [
          { required: false, message: "请输入挂单数量", trigger: "change" },
          { validator: checkAmount, trigger: "blur" }
        ], // 数量
        weight: [
          {
            required: true,
            message: "请输入挂单重量",
            trigger: "change"
          },
          { validator: checkPutHoldWeight, trigger: "change" }
        ], // 重量
        price: [
          { required: true, message: "请输入基准单价", trigger: "change" },
          { validator: positiveNum, trigger: "change" }
        ], // 单价
        startPrice: [
          { required: true, message: "请输入起拍总价", trigger: "change" },
          { validator: checkPrice, trigger: "change" }
        ], // 起拍总价
        addPrice: [
          { required: true, message: "请输入加价幅度", trigger: "change" },
          { validator: positiveNum, trigger: "blur" }
        ], // 加价幅度
        openTicketTime: [
          { required: true, message: "请选择开票期限", trigger: "change" },
          { validator: checkOpenTicketDay, trigger: "change" }
        ], // 开票期限
        contactName: [
          { required: false, message: "请选择联系人", trigger: "change" }
        ], // 联系人
        contactPhone: [
          { required: false, message: "请选择联系电话", trigger: "change" }
        ], // 联系电话
        memo: [{ required: false, message: "请选择备注", trigger: "change" }], // 备注
        image: [
          { required: false, message: "请选择商品图片", trigger: "change" }
        ], // 商品图片

        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" }
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" }
        ],
        minPickingWeight: [
          { required: false, message: "请输入最小摘单重量", trigger: "change" },
          { validator: checkHoldMinPickWeight, trigger: "change" }
        ]
      },
      categoryProps: {
        label: "Value",
        value: "Code",
        checkStrictly: false
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      // 仓储查询
      holdIdOptions: [],
      auctionRule: [],
      // 结束标志
      isOver: false,
      storageIdOptions: [],
      //  仓储详情
      details: {},
      isFromWarehouse: false,
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      validBtnTimer: null,
      putWarehousTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      selectUnitId: null
    };
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    deposit() {
      let currentPrice = util.floatMul(
        this.marginForm.price,
        this.marginForm.weight
      );
      return util.floatMul(currentPrice, this.getMarginRateGetter.marginRate);
    },
    selectCommodityTypeId() {
      return this.marginForm.holdId;
    },
    selectWarehousId() {
      return this.holdForm.holdId;
    }
  },
  watch: {
    "marginForm.holdId": {
      handler(val, oldVal) {
        this.marginForm.price = null;
        this.auctionRule = [];
        this.marginForm.addPrice = null;
        this.calcUnit("margin");
      }
    },
    "holdForm.holdId": {
      handler(val, oldVal) {
        this.holdForm.price = null;
        this.auctionRule = [];
        this.holdForm.addPrice = null;
        this.calcUnit("hold");
      }
    },
    biddingType(val, oldVal) {
      // 清状态
      window.clearTimeout(this.validBtnTimer);
      this.validButtonText = "发送验证码";
      this.validBtnDisabled = false;
      this.saleList = [];
      this.saleTimeList = [];
      this.stepForm = {
        saleTime: null,
        salePrice: null
      };
      this.biddingType = val;
      this.getCommodityCategory();
      this.$refs["holdForm"].resetFields();
      this.$refs["marginForm"].resetFields();
    },
    marginFromStartPrice(val) {
      this.marginForm.startPrice = val;
    }
  },
  methods: {
    selectChange(e) {
      console.log(e);
      console.log(this.categoryOptions);
      // if ()
      this.colList = [];
      // this.$refs.marginForm.clearValidate('addPrice');
      // this.$refs.marginForm.resetField()
    },
    calcUnit(status) {
      this.colList = [];
      console.log(this.categoryOptions);
      let that = this;
      if (status == "margin") {
        for (let j = 0; j < this.categoryOptions.length; j++) {
          if (this.categoryOptions[j].holdId == this.marginForm.holdId) {
            that.marginForm.unit = that.categoryOptions[j].unit;
            that.maxWeight = util.floatSub(
              that.categoryOptions[j].holdWeight,
              that.categoryOptions[j].frozenHoldWeight
            );

            that.currentCommodityId = that.categoryOptions[j].commodityId;
            that.maxQuantity = that.categoryOptions[j].quantity;
            that.selectUnitId = that.categoryOptions[j].unitId;
            that.marginForm.quantity = that.categoryOptions[j].quantity;
            that.marginForm.weight = that.maxWeight;
            if (that.categoryOptions[j].specificationsName) {
              let str = JSON.parse(that.categoryOptions[j].specificationsName);
              for (let key in str) {
                let obj = {};
                obj.name = key;
                obj.value = str[key];
                that.colList.push(obj);
              }
              if (that.categoryOptions[j].manufacturer) {
                let obj = {};
                obj.name = "生产厂家";
                obj.value = that.categoryOptions[j].manufacturer;
              }
              if (that.categoryOptions[j].placeOrigin) {
                let obj = {};
                obj.name = "产地";
                obj.value = that.categoryOptions[j].placeOrigin;
              }
              if (that.categoryOptions[j].brand) {
                let obj = {};
                obj.name = "品牌";
                obj.value = that.categoryOptions[j].brand;
              }
            }
            that.param_queryAuctionRule();
          }
        }
      } else {
        for (let j = 0; j < this.categoryOptions.length; j++) {
          if (this.categoryOptions[j].holdId == this.holdForm.holdId) {
            that.holdForm.unit = that.categoryOptions[j].unit;
            that.maxWeight = util.floatSub(
              that.categoryOptions[j].holdWeight,
              that.categoryOptions[j].frozenHoldWeight
            );

            that.maxQuantity = that.categoryOptions[j].quantity;
            that.currentCommodityId = that.categoryOptions[j].commodityId;
            that.selectUnitId = that.categoryOptions[j].unitId;
            that.holdForm.quantity = that.categoryOptions[j].quantity;
            that.holdForm.weight = that.maxWeight;
            if (that.categoryOptions[j].specificationsName) {
              let str = JSON.parse(that.categoryOptions[j].specificationsName);
              for (let key in str) {
                let obj = {};
                obj.name = key;
                obj.value = str[key];
                that.colList.push(obj);
              }
            }
            that.param_queryAuctionRule();
          }
        }
      }
    },
    handledialogVisible1() {
      this.dialogVisible1 = false;
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        vueThis.validBtnTimer = setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    addRangePrices() {
      this.saleList = [];
      this.saleTimeList = [];
      this.$refs["priceForm"].validate(valid => {
        if (valid) {
          this.priceDialogVisible = false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      if (this.biddingType == "marginSell") {
        //履约订金竞卖
        this.marginForm.image = res.value;
      } else if (this.biddingType == "holdSell") {
        //仓单竞卖 holdForm
        this.holdForm.image = res.value;
      }
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    showDialog(holdId, biddingType) {
      console.log(holdId, biddingType, 9999);
      this.dialogVisible = true;
      if (biddingType && biddingType == "Y") {
        this.biddingType = "marginSell";
      } else {
        //仓单竞卖 holdForm
        this.biddingType = "holdSell";
      }
      this.getCommodityCategory(holdId);
    },
    modifyStepPrice(item, index) {
      // 修改阶梯价
      this.stepForm.saleTime = item.saleTime;
      this.stepForm.salePrice = item.salePrice;
      this.centerDialogVisible = true;
      this.saleList.splice(index, 1);
      this.saleTimeList.splice(index, 1);
    },
    modifyprotePrice(item, index) {
      this.priceDialogVisible = true;
    },
    addPrices() {
      this.priceForm = {
        decreasePrice: null, // 递减价格
        protectPrice: null, // 保护价格
        saleSecond: null //递减金额
      };
      this.$refs["stepForm"].validate(valid => {
        if (valid) {
          let obj = {};
          obj.saleTime = util.dateFormate(this.stepForm.saleTime);
          obj.salePrice = this.stepForm.salePrice;
          this.saleList.push(obj);
          this.saleTimeList.push(this.stepForm.saleTime);
          this.centerDialogVisible = false;
          this.stepForm.saleTime = null;
          this.stepForm.salePrice = null;
          this.$refs["stepForm"].resetFields();
        }
      });
    },
    // 商品品种+品名
    getCommodityCategory(holdId) {
      const that = this;
      protocolFwd.param_queryHold.param.page = 0;
      protocolFwd.param_queryHold.param.size = 10000;
      // protocolFwd.param_queryHold.param.sort = [{property: "applyWarehousId", direction: "DESC"}];
      if (this.biddingType == "marginSell") {
        //履约订金竞卖
        protocolFwd.param_queryHold.param.holdType = ["Y"];
      } else if (this.biddingType == "holdSell") {
        protocolFwd.param_queryHold.param.holdType = ["X", "B"];
      } else {
        protocolFwd.param_queryHold.param.holdType = null;
      }
      protocolFwd.param_queryHold.param.sellStatus = "T";
      protocolFwd.param_queryHold.param.auditHoldStatus = "T";
      protocolFwd.param_queryHold.param.specificationsId = null;
      protocolFwd.param_queryHold.param.holdStatus = null;
      protocolFwd.param_queryHold.param.specificationsName = null;
      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.categoryOptions = value.content;
          if (value.content != 0) {
            // let str = JSON.parse(row.specificationsName)
            // for(let key in specificationsName) {
            //   for(let )
            //   let obj = {}
            //   obj.name =
            // }
          }
          that.colList = [];
          if (holdId) {
            this.marginForm.holdId = holdId;
            this.holdForm.holdId = holdId;
            this.categoryOptions.forEach(row => {
              if (holdId == row.holdId) {
                that.holdForm.util = row.unit;
                that.marginForm.util = row.unit;
                that.maxWeight = util.floatSub(
                  row.holdWeight,
                  row.frozenHoldWeight
                );
                that.maxQuantity = row.quantity;
                that.currentCommodityId = row.commodityId;
                that.selectUnitId = row.unitId;
                let str = JSON.parse(row.specificationsName);
                for (let key in str) {
                  let obj = {};
                  obj.name = key;
                  obj.value = str[key];
                  that.colList.push(obj);
                }
                if (row.manufacturer) {
                  let obj = {};
                  obj.name = "生产厂家";
                  obj.value = row.manufacturer;
                }
                if (row.placeOrigin) {
                  let obj = {};
                  obj.name = "产地";
                  obj.value = row.placeOrigin;
                }
                if (row.brand) {
                  let obj = {};
                  obj.name = "品牌";
                  obj.value = row.brand;
                }
              }
            });
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 资源管理-仓储查询
    getWarehousIdOptions(holdId) {
      protocolFwd.param_querySpotWarehouse.param.fStatus = [0];
      http.postFront(protocolFwd.param_querySpotWarehouse).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.holdIdOptions = value;
          if (holdId) {
            this.holdForm.holdId = holdId;
            this.getWarehousDetails(this.holdForm.holdId);
            this.isFromWarehouse = true;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      if (Arr) {
        Arr.forEach(element => {
          if (element && element.children) {
            if (element.children.length == 0) {
              delete element["children"];
            } else {
              this.clearChildren(element.children);
            }
          }
        });
      }
      return Arr;
    },
    getGradeIdOptions() {
      http.postFront(protocolFwd.param_getCommodityGrade).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.gradeIdOptions = [];
          for (let i = 0; i < value.length; i++) {
            if (value[i].value == "国标") {
              this.gradeIdOptions.push(value[i]);
            }
          }
          this.gradeName = this.gradeIdOptions[0].value;
          this.marginForm.gradeId = this.gradeIdOptions[0].gradeId;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getPackagIdOptions() {
      http.postFront(protocolFwd.param_getCommodityPackage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.packagIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSpecificationsIdOptions() {
      http.postFront(protocolFwd.param_getComSpecifications).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.specificationsIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 仓库列表
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 查询基准单价与减价幅度
    param_queryAuctionRule() {
      if (this.isOver) {
        return;
      }
      protocolFwd.param_queryAuctionRule.param = {};
      // if (this.biddingType == "marginSell") {
      //履约订金竞卖
      protocolFwd.param_queryAuctionRule.param = {
        commodityId: this.currentCommodityId ? this.currentCommodityId : null,
        unitId: this.selectUnitId ? this.selectUnitId : null
      };
      // } else if (this.biddingType == "holdSell") {
      //   //仓单竞卖 holdForm
      //   if (this.holdForm.holdId) {
      //     for (const i in this.holdIdOptions) {
      //       if (this.holdIdOptions.hasOwnProperty(i)) {
      //         const element = this.holdIdOptions[i];
      //         if (element.holdId == this.holdForm.holdId) {
      //           protocolFwd.param_queryAuctionRule.param = {
      //             holdId: element.holdId
      //           };
      //         }
      //       }
      //     }
      //   }
      // }
      if (
        !protocolFwd.param_queryAuctionRule.param.commodityId ||
        !this.selectUnitId
      ) {
        return;
      }
      http.postFront(protocolFwd.param_queryAuctionRule).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.length != 0) {
            let arr = [];
            arr[0] = value[0].addPriceOne;
            arr[1] = value[0].addPriceTwo;
            arr[2] = value[0].addPriceThree;
            arr[3] = value[0].addPriceFour;
            arr[4] = value[0].addPriceFive;
            if (this.biddingType == "marginSell") {
              //履约订金竞卖
              // this.marginForm.price = value[0].basePrice;
            } else if (this.biddingType == "holdSell") {
              //仓单竞卖 holdForm
              // this.holdForm.price = value[0].basePrice;
            }
            this.auctionRule = arr;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 查询仓储详情
    getWarehousDetails(choose) {
      let applyWarehousId = null;
      for (let i = 0; i < this.holdIdOptions.length; i++) {
        if (this.holdIdOptions[i].holdId === choose) {
          applyWarehousId = this.holdIdOptions[i].applyWarehousId;
        }
      }
      protocolFwd.param_queryNewWarehousingDetails.param.applyWarehousId = applyWarehousId;
      http
        .postFront(protocolFwd.param_queryNewWarehousingDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value) {
              this.details = value;
              this.holdForm.contactName = value.corporateName;
              this.holdForm.contactPhone = value.chPhone;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    confirm() {
      const that = this;
      if (this.biddingType == "holdSell") {
        if (
          this.holdForm.auctionStyle == "2" &&
          this.saleList.length == 0 &&
          this.priceForm.protectPrice == null
        ) {
          this.$EL_MESSAGE("请添加阶梯价格或跌价幅度");
          return;
        }
        that.confirmSure();
      }
      if (this.biddingType == "marginSell") {
        if (
          this.marginForm.auctionStyle == "2" &&
          this.saleList.length == 0 &&
          this.priceForm.protectPrice == null
        ) {
          this.$EL_MESSAGE("请添加阶梯价格或跌价幅度");
          return;
        }
        that.$refs["marginForm"].validate(valid => {
          if (valid) {
            that.dialogVisible1 = true;
          }
        });
      }
    },
    confirmSure() {
      if (this.biddingType == "marginSell") {
        //履约订金竞卖
        this.$refs["newForm"].validate(valid => {
          if (valid) {
            // protocolNJP.param_marginSell.param = this.form;
            protocolNJP.param_marginSell.param.showTime = Number(
              this.marginForm.showTime
            ); // 公示开始时间
            protocolNJP.param_marginSell.param.startTime = Number(
              this.marginForm.startTime
            ); // 竞拍开始时间
            protocolNJP.param_marginSell.param.endTime = Number(
              this.marginForm.endTime
            ); // 竞拍结束时间
            protocolNJP.param_marginSell.param.putWarehousTime = Number(
              this.marginForm.putWarehousTime
            ); // 预计入库时间
            protocolNJP.param_marginSell.param.manufacturer = this.marginForm.manufacturer; // 生产厂家

            protocolNJP.param_marginSell.param.quantity = Number(
              this.marginForm.quantity
            ); // 数量
            protocolNJP.param_marginSell.param.weight = Number(
              this.marginForm.weight
            ); // 重量

            protocolNJP.param_marginSell.param.price = Number(
              this.marginForm.price
            ); // 单价
            protocolNJP.param_marginSell.param.startPrice = Number(
              this.marginForm.startPrice
            ); // 起拍总价
            if (this.marginForm.auctionStyle == "1") {
              protocolNJP.param_marginSell.param.addPrice = Number(
                this.marginForm.addPrice
              );
            } else {
              protocolNJP.param_marginSell.param.addPrice = null;
            }
            if (this.marginForm.auctionStyle == "1" && this.isAuction) {
              protocolNJP.param_marginSell.param.tradeWay = this.marginForm.tradeWay;
            } else {
              protocolNJP.param_marginSell.param.tradeWay = "A";
            }
            // 加价幅度
            protocolNJP.param_marginSell.param.openTicketTime = this.marginForm.openTicketTime; // 开票期限
            protocolNJP.param_marginSell.param.contactName = this.marginForm.contactName; // 联系人
            protocolNJP.param_marginSell.param.contactPhone = this.marginForm.contactPhone; // 联系电话
            // protocolNJP.param_marginSell.param.memo = this.marginForm.memo; // 备注
            protocolNJP.param_marginSell.param.image = this.marginForm.image; // 商品图片
            protocolNJP.param_marginSell.param.businessName = this.marginForm.businessName; // 商品图片
            protocolNJP.param_marginSell.param.businessPhone = this.marginForm.businessPhone; // 商品图片

            protocolNJP.param_marginSell.param.saleSecond = Number(
              this.priceForm.saleSecond
            );
            protocolNJP.param_marginSell.param.decreasePrice = Number(
              this.priceForm.decreasePrice
            );
            protocolNJP.param_marginSell.param.protectPrice = Number(
              this.priceForm.protectPrice
            );
            protocolNJP.param_marginSell.param.holdId = Number(
              this.marginForm.holdId
            ); // 持仓id
            protocolNJP.param_marginSell.param.auctionStyle = Number(
              this.marginForm.auctionStyle // 竞拍方式
            );
            if (
              this.marginForm.auctionStyle == "2" &&
              this.saleList.length != 0
            ) {
              protocolNJP.param_marginSell.param.saleList = this.saleList;
            } else {
              protocolNJP.param_marginSell.param.saleList = null;
            }
            if (this.marginForm.tradeWay == "P") {
              protocolNJP.param_marginSell.param.minPickingWeight = this.marginForm.minPickingWeight;
            } else {
              protocolNJP.param_marginSell.param.minPickingWeight = this.marginForm.weight;
            }
            // protocolNJP.param_marginSell.param.storageId = Number(
            //   this.marginForm.storageId
            // ); // 仓库
            // protocolNJP.param_marginSell.param.code = this.newForm.code; // 验证码
            // protocolNJP.param_marginSell.param.marginPassWord = this.newForm.marginPassWord; // 资金密码
            this.isAddLoading = true;
            http
              .postNewJP(protocolNJP.param_marginSell)
              .then(response => {
                this.isAddLoading = false;
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.isOver = true;
                  this.dialogVisible = false;
                  this.dialogVisible1 = false;
                  this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                  this.$refs["marginForm"].resetFields();
                  this.$emit("reLoad");
                  this.$router.push("/sellerCenter/resource/biddingResource");
                } else {
                  this.$EL_MESSAGE(message);
                }
              })
              .catch(() => {
                this.isAddLoading = false;
              });
          }
        });
      } else if (this.biddingType == "holdSell") {
        //仓单竞卖 holdForm
        this.$refs["holdForm"].validate(valid => {
          if (valid) {
            protocolNJP.param_holdSell.param.showTime = Number(
              this.holdForm.showTime
            );
            // 公示开始时间
            protocolNJP.param_holdSell.param.startTime = Number(
              this.holdForm.startTime
            ); // 竞拍开始时间
            protocolNJP.param_holdSell.param.endTime = Number(
              this.holdForm.endTime
            ); // 竞拍结束时间
            // protocolNJP.param_holdSell.param.holdId = this.holdForm.holdId; // 仓储id
            protocolNJP.param_holdSell.param.price = Number(
              this.holdForm.price
            ); // 单价
            protocolNJP.param_holdSell.param.startPrice = Number(
              this.holdForm.startPrice
            ); // 起拍总价
            if (this.holdForm.auctionStyle == "1") {
              protocolNJP.param_holdSell.param.addPrice = Number(
                this.holdForm.addPrice
              );
            } else {
              protocolNJP.param_holdSell.param.addPrice = null;
            }
            if (this.isAuction && this.holdForm.auctionStyle == "1") {
              protocolNJP.param_holdSell.param.tradeWay = this.holdForm.tradeWay;
            } else {
              protocolNJP.param_holdSell.param.tradeWay = "A";
            }
            // 加价幅度
            protocolNJP.param_holdSell.param.saleSecond = Number(
              this.priceForm.saleSecond
            );
            protocolNJP.param_holdSell.param.decreasePrice = Number(
              this.priceForm.decreasePrice
            );
            protocolNJP.param_holdSell.param.protectPrice = Number(
              this.priceForm.protectPrice
            );
            protocolNJP.param_holdSell.param.manufacturer = this.holdForm.manufacturer; // 生产厂家
            protocolNJP.param_holdSell.param.openTicketTime = this.holdForm.openTicketTime; // 开票期限
            protocolNJP.param_holdSell.param.contactName = this.holdForm.contactName; // 联系人
            protocolNJP.param_holdSell.param.contactPhone = this.holdForm.contactPhone; // 联系电话
            // protocolNJP.param_holdSell.param.memo = this.holdForm.memo; // 备注
            protocolNJP.param_holdSell.param.image = this.holdForm.image; // 商品图片
            protocolNJP.param_holdSell.param.businessName = this.holdForm.businessName; // 业务负责人
            protocolNJP.param_holdSell.param.businessPhone = this.holdForm.businessPhone; // 联系电话

            protocolNJP.param_holdSell.param.holdId = Number(
              this.holdForm.holdId
            ); // 持仓id
            protocolNJP.param_holdSell.param.auctionStyle = Number(
              this.holdForm.auctionStyle // 竞拍方式
            );
            protocolNJP.param_holdSell.param.quantity = Number(
              this.holdForm.quantity
            ); // 数量
            protocolNJP.param_holdSell.param.weight = Number(
              this.holdForm.weight
            ); // 重量
            if (
              this.holdForm.auctionStyle == "2" &&
              this.saleList.length != 0
            ) {
              protocolNJP.param_holdSell.param.saleList = this.saleList;
            } else {
              protocolNJP.param_holdSell.param.saleList = null;
            }
            if (this.holdForm.tradeWay == "P") {
              protocolNJP.param_holdSell.param.minPickingWeight = this.holdForm.minPickingWeight;
            } else {
              protocolNJP.param_holdSell.param.minPickingWeight = this.holdForm.weight;
            }
            this.isAddLoading = true;
            http
              .postNewJP(protocolNJP.param_holdSell)
              .then(response => {
                this.isAddLoading = false;
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.isOver = true;
                  this.dialogVisible = false;
                  this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                  this.$refs["holdForm"].resetFields();
                  this.$emit("reLoad");
                  this.$router.push("/sellerCenter/resource/biddingResource");
                  if (this.isFromWarehouse) {
                    this.$router.push({
                      name: "sellerResourceBiddingResource"
                    });
                  }
                } else {
                  this.$EL_MESSAGE(message);
                }
              })
              .catch(() => {
                this.isAddLoading = false;
              });
          }
        });
      } else {
        this.$EL_MESSAGE("未知竞卖资源类型");
      }
    },
    handleClose() {
      this.marginForm = {
        auctionStyle: null, //竞拍方式 1-普通，2-跌价式，3-大宗竞拍
        holdId: null, //持仓id
        addPrice: null, // 加价幅度
        contactName: "", // 联系人
        contactPhone: "", // 联系电话
        endTime: null, // 竞拍结束时间
        image: "", // 商品图片
        memo: "", // 备注
        openTicketTime: null, // 开票期限
        manufacturer: "", // 生产厂家
        price: null, // 单价
        quantity: null, // 数量
        showTime: null, // 公示开始时间
        startPrice: null, // 起拍总价
        startTime: null, // 竞拍开始时间
        weight: null, // 重量
        unit: null, // 参持仓单位
        businessName: null,
        businessPhone: null,
        putWarehousTime: null, // 预计入库时间
        tradeWay: null,
        minPickingWeight: null //分销的话最小摘单重量
        // code: null,
        // marginPassWord: null
      };

      // this.newForm = {
      //   code: null,
      //   marginPassWord: null
      // }
      this.holdForm = {
        auctionStyle: null, //竞拍方式 1-普通，2-跌价式，3-大宗竞拍
        holdId: null, //持仓id
        addPrice: null, // 加价幅度
        contactName: "", // 联系人
        contactPhone: "", // 联系电话
        endTime: null, // 竞拍结束时间
        image: "", // 商品图片
        memo: "", // 备注
        openTicketTime: null, // 开票期限
        manufacturer: "", // 生产厂家
        price: null, // 单价
        quantity: null, // 数量
        showTime: null, // 公示开始时间
        startPrice: null, // 起拍总价
        startTime: null, // 竞拍开始时间
        weight: null, // 重量
        unit: null, // 参持仓单位
        businessName: null,
        businessPhone: null,
        tradeWay: null,
        minPickingWeight: null //分销的话最小摘单重量
      };
      this.stepForm.saleTime = null;
      this.stepForm.salePrice = null;
      this.centerDialogVisible = false;
      this.saleList = [];
      this.saleTimeList = [];
      this.priceForm = {
        decreasePrice: null, // 递减价格
        protectPrice: null, // 保护价格
        saleSecond: null //递减金额
      };
      this.$nextTick(() => {
        this.$refs["holdForm"].clearValidate();
        this.$refs["marginForm"].clearValidate();
        // this.$refs["newForm"].clearValidate();
      });
      this.isFromWarehouse = false;
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.isOver = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ #d1 .el-dialog__title {
  color: red;
  font-size: 18px;
  font-weight: 700;
}
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
.stepPriceCon {
  line-height: 30px;
}
.stepPriceBtn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  margin: 10px 30px;
  text-align: center;
  border: 1px solid #66b1ff;
  background-color: #66b1ff;
  color: #fff;
}
</style>
